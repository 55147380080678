
.Header {
    /* How it will look before scrolled */
    position: fixed;
    background: #cd853f !important;
  
    /* To give smoote change */
    -webkit-transition: all ease-out .5s;
    -moz-transition: all ease-out .5s;
    -o-transition: all ease-out .5s;
    transition: all ease-out .5s;
  }

.Header2 {
    /* How it will look before scrolled */
    position: fixed;
    background: #cd853f !important;
  
    /* To give smoote change */
    -webkit-transition: all ease-out .5s;
    -moz-transition: all ease-out .5s;
    -o-transition: all ease-out .5s;
    transition: all ease-out .5s;
  }
  .Header.scrolled {
    /* How it will look when scrolled */
    background-color: #cd853f !important;
  }


