/* body {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    display: flex;
    align-items: flex-end;
  } */
  footer {
    background-color: #121315;
    color: #a7a7a7;
    font-size: 16px;
  }
  footer * {
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    border: none;
    outline: none;
  }
  .row {
    padding: 1em 1em;
  }
  .row.primary2 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr;
    align-items: stretch;
  }
  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2em;
    min-height: 15em;
  }
  h3 {
    width: 100%;
    text-align: left;
    color: white;
    font-size: 1.4em;
    white-space: nowrap;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  li:not(:first-child) {
    margin-top: 0.8em;
  }
  ul li a {
    color: #a7a7a7;
    text-decoration: none;
  }
  ul li a:hover {
    color: #2a8ded;
  }
  .about p {
    text-align: justify;
    line-height: 2;
    margin: 0;
  }
  input,
  button {
    font-size: 1em;
    padding: 1em;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  button {
    background-color: #c7940a;
    color: #ffffff;
  }
  div.social {
    display: flex;
    justify-content: space-around;
    font-size: 2.4em;
    flex-direction: row;
    margin-top: 0.5em;
  }
  .social i {
    color: #bac6d9;
  }
  
  .copyright {
    padding: 0.3em 1em;
    background-color: #25262e;
  }
  .footer-menu{
    float: left;
      margin-top: 10px;
  }
  
  .footer-menu a{
    color: #cfd2d6;
    padding: 6px;
  
    text-decoration: none;
  }
  .footer-menu a:hover{
    color: #27bcda;
  }
  .copyright p {
    font-size: 0.9em;
    text-align: right;
  }
  @media screen and (max-width: 850px) {
    .row.primary2 {
      grid-template-columns: 1fr;
    }
  
  }
  
  .main-card:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  } 


  a.go-footer{
    border-radius: 200px;
    background: #000d1a;
    white-space: nowrap;
    outline: none;
    border: none;
    min-width: 100px;
    max-width: 200px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    color: #fff;
    font-size: 15px ;
    color: white;
    scroll-behavior: smooth;

  }


  a.go-footer:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}


/* .project-detail-img:hover {
  transform: translateY(-3px);
} */

.projects-link {
  color: black;
}

a.projects-link:hover {
  color: black;
}
